import ReturnItem from '../../fenix orders/retrun item/return-item';
import React, { useState, useEffect } from "react";
import dateFormat from 'dateformat';
// import {
//     tablePaginationClasses as classes,
// } from '@mui/base/TablePagination';
import TablePagination from '@mui/material/TablePagination';
import { ORDER_HISTORY_SERACH, RETURNS_UI_URLS } from "../../../constants.js";
// RETURNS_UI_URLS
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import './my-orders.scss';
import { Alert, Checkbox, FormControl, MenuItem, Select, Snackbar, InputAdornment, OutlinedInput, Button } from '@mui/material';
import SimpleDialog from '../controlled-popup/popup.js';
import FirstPageRoundedIcon from '@mui/icons-material/FirstPageRounded';
import LastPageRoundedIcon from '@mui/icons-material/LastPageRounded';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

function MyOrders(props) {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [obj, setObj] = useState("");
    const [filterData, setFilter] = useState([]);
    const [date, setDateValue] = useState('3Months');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [totalCount, setTotalCount] = React.useState(0)
    let navigate = useNavigate();
    const [data1, setRetailerData] = React.useState({});
    const [lineDataArray, setLineDataArray] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [selectedDummyData, setSelectedDummyData] = React.useState({});
    const [orderId, setorderId] = useState('');
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [isLoaderEnable, setIsLoaderEnable] = useState(false);
    const [showtooltip, setShowTooltip] = useState(false);
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [searchValuechange, setSearchValueChange] = React.useState(false);
    const [snackbarProp, setSnackbarProp] = useState({});
    const [searchValue, setSearchValue] = useState('');
    const [dialogType, setDialogType] = useState('');
    // const [pageForDialog, setPageForDialog] = React.useState(0);
    // const [rowsPerPageDialog, setRowsPerPageDialog] = React.useState(10);
    const [reasonData, setReasonData] = useState([]);

    // const [orderInfo, setOrderInfo] = useState({});
    // let retailerInfo = props.retailerData ? props.retailerData : JSON.parse(sessionStorage.getItem('retailerData'));
    
    let filterObj = props.data ? props.data : null;
    const [orderIdParam, setorderIdParam] = useState(null);
    const [ordernumber, setOrderNumber] = useState(null);
    const [zipcode, setZipcode] = useState('');

    const dateSelected = (event) => {
        setDateValue(event.target.value);
        setSearchValue('');
        if (event.target.value === '3Months') {
            let d = formatDate(new Date().setDate(new Date().getDate() - 90));
            setFromDate(d);
            let d1 = formatDate(new Date());
            setToDate(d1);
            setTimeout(() => {
                getOrderSearch(d, d1, '', '1', rowsPerPage);
            }, 1000);
        }
        else if (event.target.value === 'Last30days') {
            let d = formatDate(new Date().setDate(new Date().getDate() - 30));
            setFromDate(d);
            let d1 = formatDate(new Date());
            setToDate(d1);
            setTimeout(() => {
                getOrderSearch(d, d1, '', '1', rowsPerPage);
            }, 1000);
        }
        // else if (event.target.value === '6Months') {
        //     let d = formatDate(new Date().setDate(new Date().getDate() - 180));
        //     setFromDate(d);
        //     let d1 = formatDate(new Date());
        //     setToDate(d1);
        //     setTimeout(() => {
        //         getOrderSearch(d, d1,'','1',rowsPerPage);
        //     }, 1000);
        // }

    };

    // const removeunderscore = (returnstatus) => {
    //     let data = returnstatus?.replaceAll('_', ' ');
    //     return data?.toLowerCase().replace(/(?:^|[\s-/])\w/g, function (match) {
    //         return match?.toUpperCase();
    //     });;
    // }
    const formatDate = (event) => {
        return (new Date(event)).toISOString().split('T')[0];;
    }

    const formatingDateForDisplay = (date) => {
        return date ? dateFormat(new Date(date), "mmmm d, yyyy") : '';
    }

    const handleCallback = (value) => {
        if (value === '2') {
            props.passChildData(value);
        }
        else if (value) {
            setSearchValue('')
            setIsSubmitted(false);
            sessionStorage.removeItem('returnItemData');
            sessionStorage.removeItem('RPI');
            setLineDataArray([]);
            let d = formatDate(new Date().setDate(new Date().getDate() - 90));
            setFromDate(d);
            let d1 = formatDate(new Date());
            setToDate(d1);
            getOrderSearch(d, d1, null, '1', rowsPerPage, ordernumber, zipcode, orderIdParam);
        }
        else {
            setIsSubmitted(false);
            sessionStorage.removeItem('RPI');
            setLineDataArray(JSON.parse(sessionStorage.getItem('returnItemData'))?.line_data);
            setorderId(JSON.parse(sessionStorage.getItem('returnItemData'))?.order_id);
            setTimeout(() => {
                scrollToParticularSection('itemId' + selectedIndex)
            }, 1000);
            // setLineDataArray([]);
        }
        // let data2 = JSON.parse(sessionStorage.getItem('retailerData'))?.type
       let  data2 = '/my-orders' + (document.location.search ? document.location.search : '');
        navigate(data2.replace(/"/g, ""));
    }

    const openDialogForCheckbox = () => {
        setOpen(true);
    };

    const handleClearSearch = () => {
        setSearchValue('');
        getOrderSearch(fromDate, toDate, null, '1', rowsPerPage);
    }
    const [searchValueDummy, setSearchValueDummy] = useState('');
    const searchParams = (event) => {
        event.preventDefault();
        setSearchValueChange(false);
        if (searchValue.length > 3 || searchValue.length !== 0) {
            let filters = {
                "criteria_type": "Contains",
                "field_name": "order_number",
                "field_type": "TEXT",
                "value": searchValue
            }
            setSearchValueDummy(JSON.parse(JSON.stringify(searchValue)));
            getOrderSearch(fromDate, toDate, filters, '1', rowsPerPage);
        }
        else {
            getOrderSearch(fromDate, toDate, null, '1', rowsPerPage);
        }
    }

    const dialogClose = (value) => {
        setOpen(false);
        if (dialogType === 'checkbox' && value) {
            if (value) {
                let array = [];
                array.push(selectedDummyData.data)
                setLineDataArray(array);
                setorderId(selectedDummyData.id);
                setSelectedIndex(selectedDummyData.index)
                scrollToParticularSection('itemId' + selectedDummyData.index);
                sessionStorage.removeItem('returnItemData');
                sessionStorage.removeItem('selectedReasonData');
                sessionStorage.removeItem('selectedDummyReasonData');
                sessionStorage.removeItem('isSelectedLineItems');
                sessionStorage.removeItem('RPI');
            }
            else {
                scrollToParticularSection('itemId' + selectedIndex)
            }
        }
        // else if (dialogType === 'pagination' && value) {
        //     scrollToParticularSection('top-section');
        //     getOrderSearch(fromDate, toDate, '', pageForDialog, rowsPerPage);
        //     // props.passChildData('pageChanged');
        //     sessionStorage.removeItem('returnItemData');
        //     sessionStorage.removeItem('selectedReasonData');
        //     sessionStorage.removeItem('selectedDummyReasonData');
        //     sessionStorage.removeItem('isSelectedLineItems');
        //     sessionStorage.removeItem('RPI');
        // }
        // else if (dialogType === 'paginationRowsChange' && value) {
        //     setRowsPerPage(rowsPerPageDialog);
        //     setPage(1);
        //     sessionStorage.removeItem('returnItemData');
        //     sessionStorage.removeItem('selectedReasonData');
        //     sessionStorage.removeItem('selectedDummyReasonData');
        //     sessionStorage.removeItem('isSelectedLineItems');
        //     sessionStorage.removeItem('RPI');
        // }
    };

    const scrollToParticularSection = (id) => {
        const violation = document.getElementById(id);
        violation?.scrollIntoView({
            block: 'start',
            // behavior: 'smooth',
        });
    }


    useEffect(() => {
        let params = new URLSearchParams(document.location.search);
        let o = decodeURIComponent(params.get("ordernumber"));
        setOrderNumber(o);
        let orderId = params.get("orderid");        
        setorderIdParam(orderId);
        let z = params.get("zipcode");
        setZipcode(z);
        setTimeout(() => {
            if (sessionStorage.getItem('returnItemData') && sessionStorage.getItem('RPI')) {
                setObj(JSON.parse(sessionStorage.getItem('returnItemData')));
                setIsSubmitted(sessionStorage.getItem('returnItemData') ? true : false);
            }
        }, 500);
        if (JSON.parse(sessionStorage.getItem('tabIndex')) !== '2' && !document.location.href.includes('my-returns')) {
            setRetailerData(JSON.parse(sessionStorage.getItem('retailerData')));
            window.scrollTo(0, 0);
            // if (!sessionStorage.getItem('email')) {
            //     navigate('/')
            // }
            let d = formatDate(new Date().setDate(new Date().getDate() - 90));
            setFromDate(d);
            let d1 = formatDate(new Date());
            setToDate(d1);
            setSearchValue('');
            getOrderSearch(d, d1, null, '1', rowsPerPage, o, z, orderId);

        }
    }, [rowsPerPage, filterObj]);

    const getOrderSearch = async (from, to, params, pageNo, size, o, z, orderId) => {        
        setIsLoaderEnable(true);
        setLineDataArray([]);
        let d;
        //    setOrderInfo(JSON.parse(sessionStorage.getItem('orderInfo'))); 
        //    let data = JSON.parse(sessionStorage.getItem('orderInfo'));     
        if (o && z) {
            d = {
                "filters": [
                    {
                        "criteria_type": "Equals",
                        "field_name": "order_number",
                        "field_type": "TEXT",
                        "value": o
                    },
                    {
                        "criteria_type": "Equals",
                        "field_name": "buyer_address.zip_code",
                        "field_type": "TEXT",
                        "value": z
                    }
                ],
                "search_params": []
            }
        }
        else if(orderId) {
            d = {
                "filters": [
                    {
                        "criteria_type": "Equals",
                        "field_name": "order_id",
                        "field_type": "TEXT",
                        "value": orderId
                    }
                ],
                "search_params": []
            }
        } 
        else if (JSON.parse(sessionStorage.getItem('email'))) {
            d = {
                "filters": [
                    {
                        "criteria_type": "Greater than or equal",
                        "field_name": "placed_dttm.formatted_dttm",
                        "field_type": "TIMESTAMP",
                        "value": from + "T00:00:00-05:00"
                    },
                    {
                        "criteria_type": "Less than or equal",
                        "field_name": "placed_dttm.formatted_dttm",
                        "field_type": "TIMESTAMP",
                        "value": to + "T23:59:59-05:00"
                    },
                    {
                        "criteria_type": "Equals",
                        "field_name": "customer.email",
                        "field_type": "TEXT",
                        "value": JSON.parse(sessionStorage.getItem('email'))
                    },
                    // {
                    //     "criteria_type": "Equals",
                    //     "field_name": "returnable",
                    //     "field_type": "BOOLEAN",
                    //     "value": "true"
                    // }
                ],
                "search_params": []
            }
        }

        if (params) {
            d?.filters?.push(params);
        }
        try {
            const response = await fetch(ORDER_HISTORY_SERACH.ORDER_NUMBER_AND_EMAIL + (pageNo ? pageNo : '1') + '&size=' + (size ? size : 10), {
                method: 'POST',
                body: JSON.stringify(d),
                headers: {
                    'Content-Type': 'application/json',
                    'X-Fenix-Tenant-Id': (JSON.parse(sessionStorage.getItem('retailerData')).tenantId)
                }
            });
            if (!response) {
                setIsLoaderEnable(false);
                setSnackOpen(true);
                setSnackbarProp({ style: 'error', msg: 'Fetching data is unsuccessfull.' });
                setFilter(null)
            }
            if (response?.status === 200) {
                const result = await response.json();
                setTimeout(() => {
                    if (result?.data) {
                        setTotalCount(result.meta?.total_count);
                        setPage(result.meta?.current_pages - 1);
                        // for (let i = 0; i < 10; i++) {
                        //  result.data[0].shipments[0]?.line_items.push(result.data[0].shipments[0].line_items[0])                    
                        // }
                        setFilter(result?.data);
                    }
                    else {
                        setTotalCount(0);
                        setFilter(null);
                    }
                    setIsLoaderEnable(false);
                }, 1000);
            }
            else {
                setFilter(null);
                setSnackOpen(true);
                setSnackbarProp({ style: 'error', msg: 'Fetching data is unsuccessfull.' });
                setIsLoaderEnable(false);
                setTotalCount(0);
            }
        }
        catch (error) {
            setSnackOpen(true);
            setSnackbarProp({ style: 'error', msg: 'Fetching data is unsuccessfull.' });
            setIsLoaderEnable(false);
            setFilter(null);
            setTotalCount(0);
        }
    }

    const onReturnItem = async (data, linedata) => {
        let d = {
            'order_id': data.order_id,
            'order_number': data.order_number,
            'date_place': data.placed_dttm,
            'price': data.total_price?.amount,
            'currency_code': data.total_price?.currency_code,
            'address': data?.buyer_address,
            'line_data': linedata,
            'email': JSON.parse(sessionStorage.getItem('email')),
        }
        setObj(d);
        let array = [];
        for (let i = 0; i < linedata.length; i++) {
            let lineDataobj = {
                "sku": linedata[i].sku,
                "quantity": linedata[i].returnable_quantity,
                "shipment_id": linedata[i].shipment_id
            }
            array.push(lineDataobj)
        }
        let d1 = {
            "order_id": data.order_id,
            "line_items": array,
        }
        try {
            const response = await fetch(RETURNS_UI_URLS.BASE_URL + 'return/return-process-initialization', {
                method: 'POST',
                body: JSON.stringify(d1),
                headers: {
                    'Content-Type': 'application/json',
                    'X-Fenix-Tenant-Id': (JSON.parse(sessionStorage.getItem('retailerData')).tenantId)
                }
            })
            if (!response) {
                setSnackOpen(true);
                setSnackbarProp({ style: 'error', msg: 'Fetching data is unsuccessfull.' });
            }

            if (response?.status === 200) {
                const result = await response.json();
                setReasonData(result?.consolidated_return_attributes);
                sessionStorage.setItem('RPI', JSON.stringify(result));
                sessionStorage.setItem('returnItemData', JSON.stringify(d));
                // let data2 = JSON.parse(sessionStorage.getItem('retailerData'))?.type
              let  data2 = '/my-orders/return' + (document.location.search ? document.location.search : '');
                navigate(data2.replace(/"/g, ""));
                setIsSubmitted(true);
            }
            else {
                setSnackOpen(true);
                setSnackbarProp({ style: 'error', msg: 'Fetching data is unsuccessfull.' });
            }
        }
        catch (error) {
            setSnackOpen(true);
            setSnackbarProp({ style: 'error', msg: 'Fetching data is unsuccessfull.' });
        }

    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    // let data = [];
    const setCheckbox = (event, value, shipId, i, id) => {
        if (lineDataArray.length > 0 ? orderId === id : true) {
            if (event === true) {
                let array = [...lineDataArray];
                value.shipment_id = shipId;
                array.push(value)
                setLineDataArray(array);
                sessionStorage.setItem('isSelectedLineItems', true);
            }
            else {
                let array = [...lineDataArray];
                let index = array.findIndex((obj) => obj.id === value.id);
                if (index !== -1) {
                    array.splice(index, 1)
                    setLineDataArray(array);
                }
                if (array.length > 0) {
                    sessionStorage.setItem('isSelectedLineItems', true);
                }
                else {
                    sessionStorage.setItem('isSelectedLineItems', false);
                }

            }
            setSelectedIndex(i)
            setorderId(id);
        } else {
            setDialogType('checkbox');
            openDialogForCheckbox();
            value.shipment_id = shipId;
            setSelectedDummyData({ id: id, data: value, index: i });
        }

    }

    const isChecked = (data) => {
        let flag = false;
        for (let i = 0; i < lineDataArray?.length; i++) {
            if (lineDataArray[i].id === data) {
                flag = true;
                break;
            }
            else {
                flag = false;
            }
        }
        return flag;
    }

    const NoIcon = styled('span')(({ theme }) => ({
        display: 'none',
    }));

    // const searchParams = (event) => {
    //     setSearchValue(event.target.value);
    //     if (event.target.value.length > 3 || event.target.value.length !== 0) {
    //         let filters = {
    //             "criteria_type": "Equals",
    //             "field_name": "order_number",
    //             "field_type": "TEXT",
    //             "value": event.target.value
    //         }
    //         getOrderSearch(fromDate, toDate, filters,'1',rowsPerPage);
    //     }
    //     else {
    //         getOrderSearch(fromDate, toDate,'','1',rowsPerPage);
    //     }
    // }

    const HtmlTooltipForAddress = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            fontSize: theme.typography.pxToRem(12),
            border: '2px solid #dadde9',
        },
    }));
    const HtmlTooltipForDetails = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            // width: '400px',
            fontSize: theme.typography.pxToRem(12),
            border: '2px solid #dadde9',
        },
    }));
    const handleChangePage = (event, newPage) => {
        let p = newPage + 1;
        // setPageForDialog(p)
        // if (lineDataArray.length > 0) {
        //     // setDialogType('pagination');
        //     openDialogForCheckbox();
        // } else {
            setPage(p);
            scrollToParticularSection('top-section');
            getOrderSearch(fromDate, toDate, '', p, rowsPerPage);
            // props.passChildData('pageChanged');
        // }
    };

    const handleChangeRowsPerPage = (event) => {
        // setRowsPerPageDialog(parseInt(event.target.value, 10));
        // if (lineDataArray.length > 0) {
        //     // setDialogType('paginationRowsChange');
        //     openDialogForCheckbox();
        // }
        // else {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(1);
        // }
    };

    // const isReturnEligible = (event) => {
    //     let flag;
    //     for (let i = 0; i < event.length; i++) {
    //         if (event[i].return_summary === null && event[i].return_context?.eligible_to_returns) {
    //             flag = true;
    //         }
    //     }
    //     return flag;
    // }

    const quantityValueBind = (value) => {
        if (lineDataArray?.length > 0) {
            let index = lineDataArray.findIndex((obj) => obj.id === value?.id);
            if (index !== -1) {
                return lineDataArray[index].returnable_quantity;
            } else {
                return value?.returnable_quantity;
            }
        }
        else {
            return value?.returnable_quantity > 0 ? value?.returnable_quantity : '0';
        }
    }

    const quantityValueBindForNonShipped = (value) => {
        if (lineDataArray?.length > 0) {
            for (let i = 0; i < lineDataArray.length; i++) {
                if (lineDataArray[i]?.id === value?.id) {
                    return lineDataArray[i].returnable_quantity;
                }
                else {
                    return value?.quantity;
                }
            }
        }
        else {
            return value?.quantity > 0 ? value?.quantity : '0';
        }
    }

    const quantityDisable = (value) => {
        let flag = true;
        if (lineDataArray?.length > 0) {
            for (let i = 0; i < lineDataArray.length; i++) {
                if (lineDataArray[i]?.id === value?.id) {
                    flag = false;
                    break;
                }
                else {
                    flag = true;
                }
            }
        }
        else {
            flag = true;
        }
        return flag;
    }

    const quantityChange = (event, value) => {
        let array = [...JSON.parse(JSON.stringify(lineDataArray))];
        let index = array.findIndex((obj) => obj.id === value);
        if (index !== -1) {
            array[index].returnable_quantity = Number(event.target.value);
        }
        setLineDataArray(JSON.parse(JSON.stringify(array)));
    }

    const returnSummaryBind = (data) => {
        // var maxDate = new Date(Math.max.apply(null, data.map(function(e) {
        //     return new Date(e.event_dttm);
        //   })));
        return data[0]?.description?.charAt(0).toUpperCase() + data[0]?.description?.slice(1).toLowerCase()+ ' on ' + formatingDateForDisplay(data[0]?.event_dttm)
    }

    const displaySizeandColor = (size, color) => {
        return size && color ? (<span>Size: {size} &nbsp;&nbsp;|&nbsp;&nbsp; Color: {color}</span>) : (size && !color ? ('Size: '+size) : (!size && color ? ('Color: '+color) : ''))
    }

    const addDefaultImg = ev => {
        ev.target.src = "/no-img.jpg";
        ev.target.style = 'width:45%';
    }

    const handleTouchStart = () => {
        setShowTooltip(true);
    }
    const handleTouchEnd = () => {
        setShowTooltip(false)
    }

    const gobacktologin = () => {
        // let data2 = JSON.stringify(data1.type);
       let data2 = '/login'
        navigate(data2.replace(/"/g, ""));
    }
    const valueChange = (e) => {
        setSearchValue(e.target.value);
        setSearchValueChange(true);
    }

    const backspaceCall = (e) =>{
        if(e.target.value.length === 0 && e.key === 'Backspace'){
            e.preventDefault();           
            setSearchValue(e.target.value)
            searchParams(e)
        }}

    // const blue = {
    //     200: '#A5D8FF',
    //     400: '#3399FF',
    // };

    // const grey = {
    //     50: '#F3F6F9',
    //     100: '#E5EAF2',
    //     200: '#DAE2ED',
    //     300: '#C7D0DD',
    //     400: '#B0B8C4',
    //     500: '#9DA8B7',
    //     600: '#6B7A90',
    //     700: '#434D5B',
    //     800: '#303740',
    //     900: '#1C2025',
    // };
    // const CustomTablePagination = styled(TablePagination)(
    //     ({ theme }) => `
    //     & .${classes.spacer} {
    //       display: none;
    //     }
      
    //     & .${classes.toolbar}  {
    //       display: flex;
    //       flex-direction: column;
    //       align-items: flex-start;
    //       gap: 8px;
    //       background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
      
    //       @media (min-width: 768px) {
    //         flex-direction: row;
    //         align-items: center;
    //       }
    //     }
      
    //     & .${classes.selectLabel} {
    //       margin: 0;
    //     }
      
    //     & .${classes.select}{
    //       font-family: 'IBM Plex Sans', sans-serif;
    //       padding: 2px 0 2px 4px;
    //       border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    //       border-radius: 6px; 
    //       background-color: transparent;
    //       color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    //       transition: all 100ms ease;
      
    //       &:hover {
    //         background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
    //         border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
    //       }
      
    //       &:focus {
    //         outline: 3px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
    //         border-color: ${blue[400]};
    //       }
    //     }
      
    //     & .${classes.displayedRows} {
    //       margin: 0;
      
    //       @media (min-width: 768px) {
    //         margin-left: auto;
    //       }
    //     }
      
    //     & .${classes.actions} {
    //       display: flex;
    //       gap: 6px;
    //       border: transparent;
    //       text-align: center;
    //     }
      
    //     & .${classes.actions} > button {
    //       align-items: center;
    //       padding: 0;
    //       border: transparent;
    //       background-color: transparent;
    //       color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    //       transition: all 100ms ease;
      
    //       > svg {
    //         font-size: 22px;
    //       }
      
    //       &:hover {
    //         background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
    //         border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
    //       }
      
    //       &:focus {
    //         outline: 3px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
    //         border-color: ${blue[400]};
    //       }
      
    //       &:disabled {
    //         opacity: 0.3;
    //         &:hover {
    //           border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    //           background-color: transparent;
    //         }
    //       }
    //     }
    //     `,
    // );
   
    const renderForm = (
        <div className="container my-orders-wrapper" id="top-section">
            {sessionStorage.getItem('email') ? <div className="row px-16">
                <div className=" width-fit-content pl-10 pr-0 align-center">
                    {/* <label className="fw-600 width-fit-content f-14 mt-10 dark-grey-color">Your Order(s) {pageCount ? pageCount : '0'}</label> */}
                    {/* <label className={`fw-600 width-fit-content f-16 ${data1?.type}`}>Your Order(s) <span className="f-14 bg-color pd-5 br-5">{totalCount ? totalCount : '0'}</span></label> */}
                    <label className={`f-16 mmt-5px ${data1?.type}`}> <span className="fw-600 pd-5 br-5">{totalCount ? totalCount : '0'} order(s)</span> placed in </label>
                </div>
                <div className="col">
                    <FormControl sx={{ m: 0 }} className='width-150 mmt-5px'>
                        {/* <Select className="form-select p-10 search-border f-14" IconComponent={NoIcon} defaultValue={date} onChange={dateSelected}>
                                                    <MenuItem value={'Last30days'}>Last 30 days</MenuItem>
                                                    <MenuItem value={'3Months'}>Past 3 Months</MenuItem>
                                                </Select> */}
                        <Select className={`${data1?.type} form-select p-10 search-border f-14 fw-500 past-days-wrapper`} IconComponent={NoIcon} defaultValue={date} onChange={dateSelected}
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper">
                            <MenuItem className={`${data1?.type + '-menuitem'}`} value='Last30days'>Last 30 days</MenuItem>
                            <MenuItem className={`${data1.type + '-menuitem'}`} value='3Months'>Past 3 Months</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                {/* <div className='col-md-2'></div> */}
                {sessionStorage.getItem('email') && !document.location.href.includes('my-orders/return') ?
                    <div className="col-12 col-sm-12 col-md-5 col-lg-4 text-right input-wrapper">
                        <form onSubmit={searchParams}>
                            <FormControl fullWidth sx={{ m: 1 }}>
                                <OutlinedInput className={`fw-500 br-5 search-border mm-t16 f-14 ${data1?.type}`}
                                    id="outlined-adornment-amount" onKeyUp={e => backspaceCall(e)} inputProps={{ maxLength: 16, }} placeholder='Search with order number'
                                    value={searchValue} onChange={e => valueChange(e)}
                                    startAdornment={<InputAdornment position="start">
                                        <IconButton onClick={handleClearSearch} edge="end">
                                            {searchValue?.length > 0 ? <ClearIcon className='f-14' /> : null}
                                        </IconButton>
                                    </InputAdornment>}
                                    label="Amount" endAdornment={<InputAdornment position="end"><Button type='submit' disabled={!searchValuechange} ><ArrowForwardIcon className={`${!searchValuechange ? 'no-pointer-events cursor-not-allowed op-5' : 'cursor-pointer primary-color'}`} /></Button></InputAdornment>}
                                />
                            </FormControl>
                        </form>
                    </div> : ''}
            </div> : ''}
            <div className="row px-16" >
                <div className="col-12 card-wrap">
                    {filterData?.length > 0 ? (filterData?.map((data, key) => (
                        <div key={data.order_id}>
                            <div className="card my-16">
                                <div className="row m-0 line p-10 f-14 bg-color br-lf-rt-5 hide-m" key={key}>
                                    <div className="col-md col-sm col-12 mdisplay-flex mpx-5">
                                        <div className='color-text fw-500'>Order Placed</div>
                                        <div className={`fw-600 ${data1?.type} mm-l16`}>{data.placed_dttm?.formatted_dttm ? formatingDateForDisplay(data.placed_dttm.formatted_dttm) : ''}</div>
                                    </div>
                                    {data1?.type !== 'janieandjack' ? <div className="col-md col-sm col-12 mdisplay-flex mpx-5">
                                        <div className='color-text fw-500'>Total</div>
                                        <div className={`fw-600 ${data1?.type} mm-l16`}>$ {data.total_price?.amount ? data.total_price?.amount.toFixed(2) : '0'}</div>
                                    </div> : ''}
                                    <div className="col-md col-sm col-12 mdisplay-flex mpx-5">
                                        <div className='width-fit-content'>
                                            <HtmlTooltipForAddress enterTouchDelay={0}
                                                title={
                                                    <React.Fragment>
                                                        <div onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} >
                                                            <div className="fw-600 f-14">{data?.buyer_address?.name}</div>
                                                            <div className="fw-400 f-12">{data?.buyer_address?.address1}</div>
                                                            <div className="fw-400 f-12">{data?.buyer_address?.city},&nbsp;{data?.buyer_address?.province}</div>
                                                            <div className="fw-400 f-12">{data?.buyer_address?.country}, &nbsp;{data?.buyer_address?.zip_code}</div>
                                                        </div>
                                                    </React.Fragment>}>
                                                <span className='mdisplay-flex'>
                                                    <div className='color-text fw-500'>Ship to</div>
                                                    <div className={`fw-600 ${data1?.type} cursor-pointer mm-l16`}>{data?.buyer_address?.name} <span className="text-color">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                                                            <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" />
                                                        </svg></span>
                                                    </div>
                                                </span>
                                            </HtmlTooltipForAddress>
                                        </div>
                                    </div>
                                    <div className="col-md col-sm col-12 mdisplay-flex mpx-5">
                                        <div className='color-text fw-500'>Order Number</div>
                                        <div className={`fw-600 ${data1?.type} mm-l16`}>{data.order_number}</div>
                                    </div>
                                </div>
                                <div className='d-md-none m-0 p-12 f-14 bg-color br-lf-rt-5'>
                                    <div className='color-text ml-10 fw-500'>Order Number:&nbsp;
                                        <HtmlTooltipForAddress enterTouchDelay={0}
                                            title={
                                                <React.Fragment>
                                                    <div>
                                                        <div onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} className="fw-500 pd-5 tooltip-wd">
                                                            {/* <div className="row m-0 p-10 f-14 bg-color br-lf-rt-5br-lf-rt-5" key={key}>
                                                            <div className="col-md-3 col-sm-12 col-12 mdisplay-flex mpx-5 mb-10">
                                                                <div className='color-text fw-500'>Order Placed: &nbsp;</div>
                                                                <div className={`fw-600 ${data1?.type} mm-l16`}>{data.placed_dttm?.formatted_dttm ? formatingDateForDisplay(data.placed_dttm.formatted_dttm) : ''}</div>
                                                            </div>
                                                            <div className="col-md-2 col-sm-12 col-12 mdisplay-flex mpx-5 mb-10">
                                                                <div className='color-text fw-500'>Total: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </div>
                                                                <div className={`fw-600 ${data1?.type} mm-l16`}>$ {data.total_price?.amount ?  data.total_price?.amount :'0' }</div>
                                                            </div>
                                                            <div className="col-md-4 col-sm-12 col-12 mdisplay-flex mpx-5 mb-10">
                                                                <div className='color-text fw-500'>Ship to: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </div>
                                                                <div className={`fw-600 ${data1?.type} cursor-pointer mm-l16`}>{data.buyer_address.name} </div>
                                                            </div>
                                                        </div> */}
                                                            <div>Order Placed : &nbsp;{data.placed_dttm?.formatted_dttm ? formatingDateForDisplay(data.placed_dttm.formatted_dttm) : ''}</div>
                                                            {data1?.type !== 'janieandjack' ? <div>Total : &nbsp;$ {data.total_price?.amount ? data.total_price?.amount.toFixed(2) : '0'}</div> : ''}
                                                            <div>Ship to : &nbsp;{data?.buyer_address?.name}</div>
                                                        </div>
                                                    </div>
                                                </React.Fragment>}>
                                            <span
                                                className={`fw-600 ${data1?.type} text-underline`}>{data.order_number} </span>
                                        </HtmlTooltipForAddress>
                                    </div>
                                </div>
                                {data?.shipments?.length > 0 ? <div>
                                    <div className={`row m-0 pb-16 ${data?.shipments?.length > 5 ? 'max-height-50 auto-overflowY hidden-overflowX' : ''}`}>
                                        <div className="col-md-12 mt-20">
                                            {data?.shipments?.map((shipmentdata, i) => (
                                                <div key={i} id={`itemId` + key}>
                                                        <div className="f-14 fw-400 pl-10-pr-12">
                                                            Product(s) Sold and Shipped by: <span className={`fw-600 ${data1.type}`}>{shipmentdata?.vendor ? shipmentdata?.vendor : data1.vendorName}</span>
                                                        </div>
                                                    
                                                    {shipmentdata.tracking_summary?.delivery_status !== 'DELIVERED' ?
                                                        <div className="f-12 pr-10 pl-10-pr-12">
                                                            {shipmentdata.tracking_summary.latest_track_event?.event_description}
                                                        </div>
                                                        : ''}
                                                    <div className={`row mt-20  ${shipmentdata?.line_items?.length > 5 ? 'max-height-50 mb-20 auto-overflowY hidden-overflowX' : ''}`} >
                                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                                            {shipmentdata?.line_items.map((linedata, j) => (
                                                                <div className="row mb-16" key={j}>
                                                                    <div className={`col-lg-1 col-md-1 col-sm-1 col-1 checkbox-width text-center ${(shipmentdata?.tracking_summary?.delivery_status === 'DELIVERED' && (linedata.return_context?.eligible_to_returns && linedata?.returnable_quantity > 0 && linedata.return_context?.max_return_date)) ? '' : 'cursor-not-allowed'}`}>
                                                                        {/* {shipmentdata?.line_items.length > 1 && linedata.return_summary === null && linedata.return_context?.eligible_to_returns ?  */}
                                                                        {
                                                                            !(shipmentdata?.tracking_summary?.delivery_status === 'DELIVERED' && (linedata?.return_context?.eligible_to_returns && linedata?.returnable_quantity > 0 && linedata.return_context?.max_return_date)) ?
                                                                                <HtmlTooltipForAddress enterTouchDelay={0}
                                                                                    title={
                                                                                        <React.Fragment>
                                                                                            <div onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} className="fw-500 f-14">
                                                                                                {shipmentdata.tracking_summary.delivery_status === 'DELIVERED' ? (linedata.return_summary === null ?
                                                                                                    <div>{(linedata.return_context?.in_eligible_reason) ? (linedata.return_context?.in_eligible_reason) : (linedata.return_context?.max_return_date ? 'Return eligible through' : '')}
                                                                                                        &nbsp;{linedata.return_context?.max_return_date ? formatingDateForDisplay(linedata.return_context?.max_return_date?.formatted_dttm) : ''}</div> :
                                                                                                    <div >{returnSummaryBind(linedata.return_summary)}</div>) : <div className='f-14'>The Items can be returned only after they are delivered</div>}</div>
                                                                                        </React.Fragment>}>
                                                                                    {showtooltip || (
                                                                                        <Checkbox {...label} className={`${!(shipmentdata?.tracking_summary?.delivery_status === 'DELIVERED' && (linedata?.return_context?.eligible_to_returns && linedata?.returnable_quantity > 0 && linedata.return_context?.max_return_date)) ? 'cursor-not-allowed auto-pointer-events' : ''}`}
                                                                                            checked={isChecked(linedata.id)} disabled={!(shipmentdata?.tracking_summary?.delivery_status === 'DELIVERED' ? (linedata?.return_context?.eligible_to_returns && linedata?.returnable_quantity > 0 && linedata.return_context?.max_return_date) : false)}
                                                                                            onChange={e => { setCheckbox(e.target.checked, linedata, shipmentdata.id, key, data.order_id, linedata.id) }} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />
                                                                                    )}
                                                                                </HtmlTooltipForAddress>
                                                                                : <Checkbox {...label} className={`${data1?.type}`} checked={isChecked(linedata.id)} disabled={!(shipmentdata.tracking_summary.delivery_status === 'DELIVERED' ? (linedata.return_context?.eligible_to_returns && linedata.returnable_quantity > 0 && linedata.return_context?.max_return_date) : false)}
                                                                                    onChange={e => { setCheckbox(e.target.checked, linedata, shipmentdata.id, key, data.order_id, linedata.id) }} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />
                                                                        }
                                                                    </div>
                                                                    <div className="col-lg-2 margintop-4 col-md-2 col-sm-3 col-2 text-center">
                                                                        {data1.type !== 'fluentcommerce' ?
                                                                            <span>
                                                                                <img className={`img-block border-image mb-10 cursor-pointer ${!linedata.image_url ? "width-45per" : ""}`}
                                                                                    onClick={() => window.open(linedata?.routing_url ? linedata?.routing_url : data1?.store_url)} alt='linedata' src={linedata.image_url ? `${linedata.image_url}` : '/no-img.jpg'}
                                                                                    onError={addDefaultImg} style={{ width: 100 + 'px' }}></img>
                                                                            </span> : <span>
                                                                                <img className={`img-block border-image mb-10 cursor-pointer ${!linedata.image_url ? "width-45per" : ""}`}
                                                                                    onClick={() => window.open(data1?.store_url)} alt='linedata' src={linedata.image_url ? `${linedata.image_url}` : '/no-img.jpg'}
                                                                                    onError={addDefaultImg} style={{ width: 100 + 'px' }}></img>
                                                                            </span>}

                                                                    </div>
                                                                    <div className="col-lg-9 col-md-8 col-sm-8 col-8 mm-l16">
                                                                        <div className={`fw-500 f-14 ${data1?.type} cursor-pointer mb-5px ${linedata.product_name?.length > 60 ? '' : 'width-fit-content'}`}>
                                                                            <HtmlTooltipForDetails enterTouchDelay={0}
                                                                                title={
                                                                                    <React.Fragment>
                                                                                        <div onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} className="fw-500 pd-5 tooltip-wd">
                                                                                            {linedata.product_name !== null && linedata.product_name ?
                                                                                                <div className=''>Product Name : &nbsp;{linedata.product_name}</div> : ""}
                                                                                            {/* {linedata.product_description !== null && linedata.product_description ?
                                                                                                <div className=''>Description : &nbsp;{linedata.product_description}</div> : ""} */}
                                                                                            {linedata.quantity !== null && linedata.quantity >= 0 ?
                                                                                                <div>Ordered Qty : &nbsp;{linedata.quantity}</div> : ''}
                                                                                            {linedata.returnable_quantity !== null && linedata.returnable_quantity >= 0 ?
                                                                                                <div>Returnable Qty : &nbsp;{linedata.returnable_quantity}</div> : ''}
                                                                                        </div>
                                                                                    </React.Fragment>}>
                                                                                {showtooltip || (
                                                                                    <span className='mdisplay-flex'>
                                                                                        <div className='display-ellipsis'>{linedata.product_name}</div>
                                                                                    </span>
                                                                                )}
                                                                            </HtmlTooltipForDetails>
                                                                        </div>
                                                                        {(linedata?.color || linedata?.size) ? <div className='row  mb-5px f-12 '>
                                                                            <div className='col-12 dark-grey-color fw-500'> {displaySizeandColor(linedata?.size, linedata?.color)}</div>
                                                                        </div>:''}
                                                                        {shipmentdata.tracking_summary.delivery_status === 'DELIVERED' ? (linedata.return_summary === null ?
                                                                            (linedata.return_context?.max_return_date ? <div className={`f-14 fw-500 ${linedata.return_context?.eligible_to_returns ? data1.type : "warning-color"}`}>
                                                                                {(linedata.return_context?.in_eligible_reason) ?
                                                                                    (linedata.return_context?.in_eligible_reason) : (linedata.return_context?.max_return_date ? 'Return eligible through' : '')}
                                                                                &nbsp;{linedata.return_context?.max_return_date ? formatingDateForDisplay(linedata.return_context?.max_return_date?.formatted_dttm) : ''}</div> : '') : <div className={`f-14 fw-500 ${linedata.return_summary !== null ? 'janieandjack-red-color' : "warning-color"}`}>
                                                                                {returnSummaryBind(linedata.return_summary)}
                                                                                {/* {linedata.return_summary[0]?.description}&nbsp;&nbsp;{linedata.return_summary[0]?.event_dttm ? formatingDateForDisplay(linedata.return_summary[0].event_dttm) : ''} */}
                                                                            </div>) : <div className={`f-14 fw-500 dark-grey-color`}>The Items can be returned only after they are delivered</div>}
                                                                        <div className='mb-16 f-12 display-flex align-items-center qty-wrap custom-select'>
                                                                            <label className='mb-5px'>Returnable Qty:</label>
                                                                            <div>
                                                                                <React.Fragment key={j}>
                                                                                    <FormControl sx={{ m: 1, minWidth: 90, mb: 2 }} size="small" variant='outlined'>
                                                                                        <Select IconComponent={NoIcon} value={quantityValueBind(linedata) ? quantityValueBind(linedata) : '0'} disabled={quantityDisable(linedata)} onChange={e => quantityChange(e, linedata.id)} className={`${data1.type} form-select ml-10 box-shadow-none cursor-pointer f-14 ${quantityDisable(linedata) ? 'cursor-not-allowed' : ''}`}>
                                                                                            {(linedata?.returnable_quantity > 0) ? (new Array((linedata?.returnable_quantity > 0) ? (linedata?.returnable_quantity) : 0).fill("").map((_, i) => i + 1).map((num, n) => { return <MenuItem className={`f-14 ${data1.type + '-menuitem'}`} key={n} value={num}>{num}</MenuItem> })) : <MenuItem className={`f-14 ${data1.type + '-menuitem'}`} value="0">0</MenuItem>}
                                                                                        </Select>
                                                                                    </FormControl>
                                                                                    {/* <span className='mb-5px ml-10 f-16'> + </span> <span className='mb-5px ml-10 f-14'> {quantityValueBind(linedata)}</span> <span className='mb-5px ml-10 f-16'> - </span> */}
                                                                                </React.Fragment>
                                                                            </div>
                                                                            {/* </div> */}

                                                                            {/* <button className={`${data1.type} tertiary-btn w-125`} onClick={() =>window.open(linedata.routing_url)}>Buy it again </button> */}
                                                                            {/* <button className=" secondary-btn w-125 ml-10 mm-l0 mm-t10" onClick={() =>window.open(linedata.routing_url)}>View your item </button> */}
                                                                        </div>
                                                                        {/* : '' }     */}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        {/* <div className="col-lg-3 col-md-3 col-sm-3">
                                                    <div className='row text-right mr-10 mm-x0'> */}
                                                        {/* {isReturnEligible(shipmentdata?.line_items) ? 
                                                        !(lineDataArray.length > 0 && shipmentdata.id === shipmentId) ? 
                                                        <HtmlTooltip enterTouchDelay={0}
                                                        title={
                                                            <React.Fragment>
                                                                <div onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}  className="fw-500">Please select lineitems to enable button</div>
                                                            </React.Fragment>}>
                                                            {showtooltip || (
                                                        <button className={`${data1?.type ? data1.type+'-btn':''}  mb-16 float-right ${lineDataArray.length > 0 && shipmentdata.id === shipmentId ? '' : 'cursor-not-allowed op-5'}`} disabled={lineDataArray.length > 0 && shipmentdata.id === shipmentId ? false : true} onClick={() => onReturnItem(data, shipmentdata, lineDataArray)}>
                                                        Return Item
                                                    </button>
                                                      )}
                                                    </HtmlTooltip>
                                                     : <button className={`${data1?.type ? data1.type+'-btn':''}  mb-16 float-right ${lineDataArray.length > 0 && shipmentdata.id === shipmentId ? '' : 'cursor-not-allowed op-5'}`} disabled={lineDataArray.length > 0 && shipmentdata.id === shipmentId ? false : true} onClick={() => onReturnItem(data, shipmentdata, lineDataArray)}>
                                                             {lineDataArray.length > 1 ? 'Return Items' :'Return Item'}
                                                         </button>
                                                       : ""} */}
                                                        {/* <button className="primary-btn mb-10 float-right" onClick={() => onReturnItem(data, shipmentdata, linedata)}>
                                                                Return or Exchange Items
                                                            </button> */}
                                                        {/* <button className=" secondary-btn mb-10 cursor-not-allowed op-5 ">Track Package
                                                        </button> */}
                                                        {/* <button className=" secondary-btn cursor-not-allowed op-5">Write a Product Review
                                                            </button> */}
                                                        {/* </div>
                                                </div> */}
                                                    </div>
                                                </div>))}
                                        </div>
                                        {/* <div className="col-lg-2 col-md-2 col-sm-2"> */}

                                        {/* </div> */}
                                    </div>
                                    <div className='row text-right mr-10 ml-10 mm-x0'>
                                        <div className={`${data1.type === 'fluentcommerce' ? 'col-sm-0' : 'col-sm'} col-lg col-md`}></div>
                                        {data1.type === 'fluentcommerce' ?
                                            <div className="col-lg-2 col-md-3 col-sm-4">
                                                <button className={`${data1.type} tertiary-btn full-width mb-16`} title='Extend Pickup Window'> Extend Pickup Window </button>
                                            </div> : ''}
                                        <div className="col-lg-2 col-md-3 col-sm-4">
                                            {/* <button className={`${data1?.type ? data1.type+'-btn':''}  mb-16 float-right ${lineDataArray.length > 0 ? '' : 'cursor-not-allowed op-5'}`} onClick={() => onReturnItem(data, lineDataArray)}>
                                                             {lineDataArray.length > 1 ? 'Return Items' :'Return Item'}
                                                         </button> */}
                                            {
                                                !(lineDataArray?.length > 0 && data?.order_id === orderId) ?
                                                    <HtmlTooltipForAddress enterTouchDelay={0}
                                                        title={
                                                            <React.Fragment>
                                                                <div onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} className="fw-500">Please select lineitems to enable button</div>
                                                            </React.Fragment>}>
                                                        {showtooltip || (
                                                            <button disabled={!(lineDataArray?.length > 0 && data.order_id === orderId)} className={`${data1?.type ? data1.type + '-btn' : ''} ${data1?.type === 'fluentcommerce' ? 'full-width' : ''} mb-16 float-right ${lineDataArray?.length > 0 && data.order_id === orderId ? '' : 'cursor-not-allowed  op-5 disable-btn'}`} onClick={() => onReturnItem(data, lineDataArray)} >
                                                                Return Item(s) &nbsp;<ArrowForwardIcon className="f-20 " />
                                                            </button>
                                                        )}
                                                    </HtmlTooltipForAddress>
                                                    : <button disabled={!(lineDataArray?.length > 0 && data.order_id === orderId)} className={`${data1?.type ? data1.type + '-btn' : ''} ${data1?.type === 'fluentcommerce' ? 'full-width' : ''} mb-16 float-right ${lineDataArray.length > 0 && data.order_id === orderId ? '' : 'cursor-not-allowed op-5'}`} onClick={() => onReturnItem(data, lineDataArray)} title='Return Item(s)'>
                                                        Return Item(s) &nbsp;<ArrowForwardIcon className="f-20 " />
                                                    </button>
                                            }
                                            {/* <button className="primary-btn mb-10 float-right" onClick={() => onReturnItem(data, shipmentdata, linedata)}>
                                                                Return or Exchange Items
                                                            </button> */}
                                            {/* <button className=" secondary-btn mb-10 cursor-not-allowed op-5 ">Track Package
                                                        </button> */}
                                            {/* <button className=" secondary-btn cursor-not-allowed op-5">Write a Product Review
                                                            </button> */}
                                        </div>
                                        {data1.type === 'fluentcommerce' ?
                                            <div className="col-lg-2 col-md-3 col-sm-4">
                                                <button className={`${data1.type} tertiary-btn full-width mb-16`} title='Cancel Order'> Cancel Order </button>
                                            </div> : ''}
                                    </div>
                                </div> : ''}
                                {data?.non_shipped_line_items?.map((nonshippeddata, i) => (
                                    <div className="row m-0 " key={i}>
                                        <div className="col-md-12">
                                            {i === 0 ?
                                                <div className={`${data?.shipments.length > 0 ? 'dashed-line' : ''}`}>
                                                    <div className="mt-20 f-14 fw-700 pl-10-pr-12 dark-grey-color">
                                                        Not Shipped Yet
                                                    </div></div> : ''}
                                            <div className="row mt-20">
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <div className='row mb-16'>
                                                        <div className='col-lg-1 col-md-1 col-sm-1 col-1 checkbox-width text-center cursor-not-allowed'>
                                                            <HtmlTooltipForAddress enterTouchDelay={0}
                                                                title={
                                                                    <React.Fragment>
                                                                        <div onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} className="fw-500 f-14">The Items can be returned only after they are delivered</div>
                                                                    </React.Fragment>}>
                                                                {showtooltip || (
                                                                    <Checkbox {...label} disabled={true} className='cursor-not-allowed auto-pointer-events' sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />
                                                                )}
                                                            </HtmlTooltipForAddress>
                                                        </div>
                                                        <div className="col-lg-2 margintop-4 col-md-2 col-sm-3 col-2 text-center">
                                                            {data1.type !== 'fluentcommerce' ? <span>
                                                                <img className={`img-block border-image mb-10 cursor-pointer ${!nonshippeddata.image_url ? "width-45per" : ""}`}
                                                                    onClick={() => window.open(nonshippeddata?.routing_url ? nonshippeddata?.routing_url : data1?.store_url)} alt='linedata' src={nonshippeddata.image_url ? `${nonshippeddata.image_url}` : '/no-img.jpg'}
                                                                    onError={addDefaultImg} style={{ width: 100 + 'px' }}></img>
                                                            </span> : <span>
                                                                <img className={`img-block border-image mb-10 cursor-pointer ${!nonshippeddata.image_url ? "width-45per" : ""}`}
                                                                    onClick={() => window.open(data1.store_url)} alt='linedata' src={nonshippeddata.image_url ? `${nonshippeddata.image_url}` : '/no-img.jpg'}
                                                                    onError={addDefaultImg} style={{ width: 100 + 'px' }}></img>
                                                            </span>}
                                                        </div>
                                                        <div className="col-lg-9 col-md-8 col-sm-8 col-8 mm-l16">
                                                            <div className={`fw-500 f-14 ${data1?.type} cursor-pointer mb-5px ${nonshippeddata.product_name?.length > 60 ? '' : 'width-fit-content'}`}>
                                                                <HtmlTooltipForDetails enterTouchDelay={0}
                                                                    title={
                                                                        <React.Fragment>
                                                                            <div>
                                                                                <div onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} className="fw-500 pd-5 tooltip-wd">
                                                                                    {/* {nonshippeddata.product_description !== null && nonshippeddata.product_description ?
                                                                                        <div>Description : &nbsp;{nonshippeddata.product_description}</div> : ''} */}
                                                                                    {nonshippeddata.product_name !== null && nonshippeddata.product_name ?
                                                                                    <div className=''>Product Name : &nbsp;{nonshippeddata.product_name}</div> : ""}
                                                                                    {nonshippeddata.quantity !== null && nonshippeddata.quantity >= 0 ?
                                                                                        <div>Ordered Qty : &nbsp;{nonshippeddata.quantity}</div> : ''}
                                                                                    {/* {nonshippeddata.product_description !== null && nonshippeddata.product_description ? 'Description :' + nonshippeddata. : ''} */}
                                                                                </div>
                                                                            </div>
                                                                        </React.Fragment>}>
                                                                    {showtooltip || (
                                                                        <span className='mdisplay-flex'>
                                                                            <div className='display-ellipsis'>{nonshippeddata.product_name}</div>
                                                                        </span>
                                                                    )}
                                                                </HtmlTooltipForDetails>
                                                            </div>
                                                            {(nonshippeddata?.color || nonshippeddata?.size) ? <div className='row mb-5px f-12 '>
                                                                <div className='col-12 dark-grey-color fw-500'> {displaySizeandColor(nonshippeddata?.size, nonshippeddata?.color)}</div>
                                                                        </div>:''}
                                                            {/* <div className="fw-500 f-14 ${data1?.type} cursor-pointer display-ellipsis" title={nonshippeddata.product_description}>{nonshippeddata.product_name}</div> */}
                                                            {/* <div className='fw-500 f-14 dark-grey-color'>{nonshippeddata.product_description}</div> */}
                                                            <div className='fw-500 f-14 dark-grey-color'>The Items can be returned only after they are delivered</div>
                                                            <div className='f-12 display-flex align-items-center qty-wrap custom-select'>
                                                                <label className='mb-5px'>Returnable Qty:</label>
                                                                <div>
                                                                    <React.Fragment key={i}>
                                                                        <FormControl sx={{ m: 1, minWidth: 90, mb: 2 }} size="small" variant='outlined'>
                                                                            <Select IconComponent={NoIcon} value={quantityValueBindForNonShipped(nonshippeddata)} disabled={quantityDisable(nonshippeddata)} onChange={e => quantityChange(e, nonshippeddata.id)} className={`${data1.type} form-select ml-10 box-shadow-none cursor-pointer f-14 ${quantityDisable(nonshippeddata) ? 'cursor-not-allowed' : ''}`}>
                                                                                {new Array(nonshippeddata?.quantity).fill("").map((_, i) => i + 1).map((num, n) => { return <MenuItem className={`f-14 ${data1.type + '-menuitem'}`} key={n} value={num}>{num}</MenuItem> })}
                                                                            </Select>
                                                                        </FormControl>
                                                                    </React.Fragment>
                                                                </div>
                                                                {/* <button className={`${data1.type} tertiary-btn w-125`} onClick={() =>window.open(linedata.routing_url)}>Buy it again </button> */}
                                                                {/* <button className=" secondary-btn w-125 ml-10 mm-l0 mm-t10" onClick={() =>window.open(linedata.routing_url)}>View your item </button> */}
                                                            </div>
                                                            {/* {nonshippeddata?.routing_url ? <div className='row p-12'> */}
                                                            {/* <button className={`${data1.type} tertiary-btn w-125`} onClick={() => window.open(nonshippeddata.routing_url)}>Buy it again </button> */}
                                                            {/* <button className=" secondary-btn w-125 ml-10 mm-l0 mm-t10" onClick={() => window.open(nonshippeddata.routing_url)}>View your item </button> */}
                                                            {/* </div> : ''} */}
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="col-lg-3 col-md-3 col-sm-3">
                                                    <div className='row text-right mr-10 mm-x0'>
                                                         <button className=" secondary-btn mb-10 cursor-not-allowed op-5 ">Track Package </button> 
                                                         <button className=" secondary-btn cursor-not-allowed op-5">Write a Product Review </button> 
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))) : filterData === null ? <div className={`${data1.type} f-14 fw-500 text-center min-height-50 align-center mt-16`}>
                        <div className='no-orders-border-line p-20'>
                        Welcome to <span className="fw-700">{data1?.vendorName}</span> Return Portal.
                        {searchValueDummy ? 
                        <div>It looks like you don’t have an order for <span className="fw-700 display-ellipsis">Order Number: {searchValueDummy}</span></div> 
                        :(sessionStorage.getItem('email') ? <div>It looks like you don’t have any orders for <span className="fw-700 display-ellipsis">{JSON.parse(sessionStorage.getItem('email'))}</span></div> 
                        : (ordernumber ? <div>It looks like you don’t have an order for <span className="fw-700 display-ellipsis">Order Number:  {ordernumber}  & Zipcode:  {zipcode}</span></div> : <div>It looks like you don’t have an order for <span className="fw-700 display-ellipsis">Order Id:  {orderIdParam}</span></div>))}
                        <div><a href={() => false} className={`${data1.type} fw-700 cursor-pointer`} onClick={gobacktologin}>Logout</a></div> </div></div> : ''}
                </div>
            </div>
            {totalCount > 10 ?
                <div className='pagination float-right mb-16 pgr-10 color-text fw-500'>
                    <TablePagination
                        component="div"
                        count={totalCount}
                        page={page}
                        className={`${data1?.type} table-page`}
                        showFirstButton
                        showLastButton
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        slots={{
                            firstPageIcon: FirstPageRoundedIcon,
                            lastPageIcon: LastPageRoundedIcon,
                            nextPageIcon: ChevronRightRoundedIcon,
                            backPageIcon: ChevronLeftRoundedIcon,
                        }}
                        slotProps={{
                            select: {
                                'aria-label': 'Rows per page',
                            },
                        }}
                    />
                </div> : ''
            }
            <SimpleDialog
                open={open}
                onClose={dialogClose}
            />
        </div>
    )

    return (
        <div>
            {isLoaderEnable && !searchValue ?
                // <div className="loading">
                <div className="min-height-50 align-center loader">Loading your orders...</div>
                //  </div>
                : (
                    isSubmitted ? <div><ReturnItem childData={handleCallback} data={obj} reasonData={reasonData} /></div> : renderForm)
            }
            <Snackbar
                open={snackOpen}
                autoHideDuration={5000}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
            >
                <Alert
                    onClose={handleClose}
                    severity={snackbarProp.style}
                    variant="filled"
                    sx={{ width: '100%' }}
                >{snackbarProp.msg}
                </Alert></Snackbar>
        </div>
    );
}

export default MyOrders;