import './pageNotFound.scss'
import { useNavigate } from 'react-router-dom';
import Navbar from "../navbar/navbar";
import Footer from "../footter/footer";

const PageNotFound = (data) => {
  let data1 = (data?.data) ? (data?.data) : JSON.parse(sessionStorage.getItem('retailerData'));
 
  let navigate = useNavigate();
  // const [data1, setRetailerData] = React.useState({});

  const gobacktologin = () => {
    let data2 = JSON.stringify(data1.type);
    data2 = '/login'
    navigate(data2.replace(/"/g, ""));
}

    return (
      <div>
      <Navbar data={data1}></Navbar>
      <div className="not-found-container">
      <h1 className={`${data1?.type} fw-600`}>OOPS!</h1>
      <p className={`${data1?.type} error-message`}>The page you requested was not found.</p>
      <p className={`${data1?.type} error-description`}>
          Sorry! The page you were looking for could not be found.<br />
          This page may have been moved or deleted.
      </p>
      {/* <a href="/janieandjack" className="home-button">Go to Login Page</a>  */}
       <button className={`${data1?.type ? data1.type+'-btn':''} ${data1?.type === 'fluentcommerce' ? 'full-width' : ''} mb-16 float-right`} onClick={gobacktologin} >
      Go to Login Page </button>
  </div>
  <Footer data={data1}></Footer>
  </div>
    )
}
  
export default PageNotFound;