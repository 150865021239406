import React, { useEffect,useState } from "react";
import { RETURNS_UI_URLS } from "../../../constants";
import PropTypes from 'prop-types';
import ClearIcon from '@mui/icons-material/Clear';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { FormControl,OutlinedInput } from "@mui/material";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';


function EmailDialog(props){
    const [data1, setRetailerData] = React.useState({});
    const [errorMessages, setErrorMessages] = useState({});
    const [radiovalue, setRadioValue] = React.useState('orderplacedemail'); 
    const isValidEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const [emailValid, setEmailValid] = useState(false);
    const [emailvalue, setEmailValue] = React.useState('');
    const [confirmEmailValue , setConfirmEmailValue]=React.useState('');
    const [emailverified, setEmailVerified] = React.useState(false);
    const { onClose, open } = props;
    const handleClose = ( isChecked ) => {
      onClose(isChecked)
      setEmailValue('');
      setConfirmEmailValue('');
      setErrorMessages({ name: "email", message: null });
    };

    useEffect(()=>{
      setRadioValue('orderplacedemail')
      setErrorMessages({ name: "email", message: null });
        setRetailerData(JSON.parse(sessionStorage.getItem('retailerData')));
    },[])

   const handleSetValue = (value) =>{
        setEmailValue('');
        setConfirmEmailValue('');
        setErrorMessages({ name: "email", message: null });
        setRadioValue(value);
    }

    const emailChange = (event) => {
        setEmailValue(event.target.value);
        if(event.target?.value && isValidEmail.test(event.target?.value)){
          setEmailValid(true);
        }else{
          setEmailValid(false);
        }
        setErrorMessages({ name: "email", message: null });
      }
    const confirmEmail =  (event) =>{
        setConfirmEmailValue(event.target.value);
        if(event.target?.value && isValidEmail.test(event.target?.value)){
           setEmailValid(true);
           if(event.target.value === emailvalue){
            setEmailVerified(true);
            setErrorMessages({ name: "email", message: 'Email matched' });
           }
           else{
          setErrorMessages({ name: "email", message: 'Email not matched' });
            setEmailVerified(false);
          }
        }
        else{
          setErrorMessages({ name: "email", message: 'Email not matched' });
            setEmailValid(false);
            setEmailVerified(false);
        }
    }

     const sendEmail = async () => {
      let email = '';
      if(radiovalue === 'otheremail'){
        email = emailvalue;
      }
      else{
        email = props?.emailData?.emailId;

      }
        let d = {}
        const response = await fetch(RETURNS_UI_URLS.BASE_URL + 'returns/' + props?.emailData?.returnId + '/shipment/' + props?.emailData?.shipId + '/send-notification?return_status=RETURN_CONFIRMED&email_to='+email, {
            method: 'POST',
            body: JSON.stringify(d),
            headers: {
                'Content-Type': 'application/json',
                'X-Fenix-Tenant-Id': JSON.parse(sessionStorage.getItem('tenantId')),
            }
        });
        if (response?.status === 200) {
            const result = await response.json();
            // setSnackOpen(true);
            // setSnackbarProp({ style: 'success', msg: 'Notification sent to ' + result?.return_summary?.email_id });
             handleClose(result);
        }
    }

    const renderErrorMessage = (name) =>
        name === errorMessages.name && (
          <span className={`f-12 mt-5px ${errorMessages.message === 'Email matched' ? 'success-color':'error-color'}`}>{errorMessages.message}</span>
        );
       

    return (
        <div>
        <Dialog open={open}>
          <DialogTitle  className="fw-500"><div className="row">
            <div className="col-11 col-md-11 col-lg-11"> Please select email to send return details</div>
            <div className="col-1 col-md-1 col-lg-1"> <ClearIcon className='cursor-pointer' onClick={() => handleClose(false)}/></div></div></DialogTitle>
          <DialogContent className="mt-10">
              <DialogContentText id="alert-dialog-description">
                <FormControl className="mb-10">
                <RadioGroup  name="row-radio-buttons-group" value={radiovalue} defaultValue={'orderplacedemail'}>
                <FormControlLabel value="orderplacedemail"  onClick={e => handleSetValue('orderplacedemail')} control={<Radio  sx={{'& .MuiSvgIcon-root': {fontSize: 20,},}} className={`${data1.type}`} />}   label={<span style={{ fontWeight: '400' }}>{props?.emailData?.emailId}</span>} className={`f-14 fw-400 ${data1.type}`}/>
                <FormControlLabel value="otheremail" onClick={e => handleSetValue('otheremail')} control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 20,},}} className={`${data1.type}`} />} label={<span style={{ fontWeight: '400' }}>Other email</span>} className={`f-14 fw-400 ${data1.type}`} />
                </RadioGroup>
                </FormControl>
              </DialogContentText>
              {radiovalue === 'otheremail' ? <div className="input-field">
               <form className="mt-10 mb-10">
               <FormControl fullWidth sx={{ m: 1 }}>
                    <div className="text-start mb-5px  fw-500 f-14 required">Enter Email</div>
                                        <OutlinedInput className={`full-width p-10 br-5 login-border f-14 ${data1.type}`} sx={{'& ::placeholder':{fontSize:'12px'}}} placeholder="Enter Email"
                                            type="text" name="email"  value={emailvalue} onChange={emailChange} required
                                            label="email"/>
                                            {/* {renderErrorMessage("Email")} */}
                                    </FormControl>
                    <FormControl fullWidth sx={{ m: 1 }}>
                    <div className="text-start mb-5px mt-10 fw-500 f-14 required">Confirm Email</div>
                                     <OutlinedInput type="text" name="confirmEmail" placeholder="Confirm Email" sx={{'& ::placeholder':{fontSize:'12px'}}}
                                      className={`full-width p-10 br-5 login-border f-14 ${data1.type}`} value={confirmEmailValue} onChange={confirmEmail} required
                                            label="Confirm Email"/>
                                    {renderErrorMessage("email")}
                                    </FormControl>
               </form>
              </div> :'' }
            </DialogContent>
          <DialogActions className="justify-content-center">
          <button onClick={() => handleClose(false)} className={`${data1?.type} tertiary-btn`}>Cancel</button>
          <button onClick={() => sendEmail()} disabled={radiovalue === 'otheremail' ? (!emailvalue || !confirmEmailValue || !emailValid || !emailverified) : false} className={` ${data1?.type ? data1.type+'-btn':''} ${radiovalue === 'otheremail' ? ((!emailvalue ||  !confirmEmailValue || !emailValid || !emailverified )? 'cursor-not-allowed op-5': 'cursor-pointer'):''}`}>send</button>
        </DialogActions>
        </Dialog>
        </div>
      );
    }
    EmailDialog.propTypes = {
      onClose: PropTypes.func.isRequired,
      open: PropTypes.bool.isRequired,
    };
    
    export default EmailDialog;