// import { BrowserRouter as  Router, Routes, Route, Navigate } from 'react-router-dom';
// import React, { useEffect } from 'react';
// import Loginportal from './return portal/return-portal';
import JanieandJack from './jakieandjack/jakieandjack';
import ShopSimon from './shopsimon/shopsimon';
import WearHumans from './wear-humans/wear-humans';
import TravelPro from './travelPro/travel-pro';
import FluentCommerce from './fluent-commerce/fluent-commerce';
import HatchCollections from './hatchcollections/hatchcollections';
// import changeFavicon from './changefavicon';
import PageNotFound from './page-not-found/pageNotFound'
import { Navigate, Route, Routes } from 'react-router-dom';

const Routing = ()=> {

  // const location = useLocation();

  // useEffect(() => {
  //   if (location.pathname.includes('janieandjack')) {
  //     changeFavicon('/ups.svg');
  //   } else if (location.pathname.includes('fluentcommerce')) {
  //     changeFavicon('/ups.svg');
  //   } else if (location.pathname.includes('shopsimon')) {
  //     changeFavicon('/ups.svg');
  //   } else if (location.pathname.includes('hatchcollections')) {
  //     changeFavicon('/ups.svg');
  //   } else {
  //     changeFavicon('/fenixcommerce.png');
  //   }
  // }, [location]);
     
    return(
        // <Router>
          <Routes>
          {/* {document.location.hostname === 'janieandjack.returns.fenixcommerce.com' && <Route exact path="/" element={<Navigate to="/login" replace/>} />}
          <Route path="" element = {<JanieandJack/>}/>
          <Route path="/" element = {<JanieandJack/>}/>
          <Route path="/login" element = {<JanieandJack/>}/>
          <Route path="/my-orders" element={<JanieandJack/>} />
          <Route path="/my-orders/:ordernumber/:zipcode" element={<JanieandJack/>} />
          <Route path="/my-orders/:orderid" element={<JanieandJack/>} />
          <Route path="/my-orders/return" element={<JanieandJack/>} />
          <Route path="/my-orders/return/:orderid" element={<JanieandJack/>} />
          <Route path="/my-orders/return/:ordernumber/:zipcode" element={<JanieandJack/>} />
          <Route path="/my-returns" element={<JanieandJack/>} />
          <Route path="/my-returns/:ordernumber/:zipcode" element={<JanieandJack/>} />
          <Route path="/my-returns/:orderid" element={<JanieandJack/>} />
          <Route path="" element={<PageNotFound/>} />


          {document.location.hostname === 'selfservice.ppe.fluentcommerce.com' && <Route exact path="/" element={<Navigate to="/login" replace/>} />}
          <Route path="" element = {<FluentCommerce/>}/>
          <Route path="/" element = {<FluentCommerce/>}/>
          <Route path="/login" element = {<FluentCommerce/>}/>
          <Route path="/my-orders" element={<FluentCommerce/>} /> 
          <Route path="/my-orders/:ordernumber/:zipcode" element={<FluentCommerce/>} />
          <Route path="/my-orders/:orderid" element={<FluentCommerce/>} />
          <Route path="/my-orders/return" element={<FluentCommerce/>} />
          <Route path="/my-orders/return/:ordernumber/:zipcode" element={<FluentCommerce/>} />
          <Route path="/my-orders/return/:orderid" element={<FluentCommerce/>} />
          <Route path="/my-returns" element={<FluentCommerce/>} />
          <Route path="/my-returns/:ordernumber/:zipcode" element={<FluentCommerce/>} />
          <Route path="/my-returns/:orderid" element={<FluentCommerce/>} />

          <Route path="" element = {<ShopSimon/>}/>
          <Route path="/" element = {<ShopSimon/>}/>
          <Route path="/login" element = {<ShopSimon/>}/>
          <Route path="/my-orders" element={<ShopSimon/>} />
          <Route path="/my-orders/:ordernumber/:zipcode" element={<ShopSimon/>} />
          <Route path="/my-orders/:orderid" element={<ShopSimon/>} />
          <Route path="/my-orders/return" element={<ShopSimon/>} />
          <Route path="/my-orders/return/:ordernumber/:zipcode" element={<ShopSimon/>} />
          <Route path="/my-orders/return/:orderid" element={<ShopSimon/>} />
          <Route path="/my-returns" element={<ShopSimon/>} />
          <Route path="/my-returns/:ordernumber/:zipcode" element={<ShopSimon/>} />
          <Route path="/my-returns/:orderid" element={<ShopSimon/>} />

          <Route path="" element = {<HatchCollections/>}/>
          <Route path="/" element = {<HatchCollections/>}/>
          <Route path="/login" element = {<HatchCollections/>}/>
          <Route path="/my-orders" element={<HatchCollections/>} />
          <Route path="/my-orders/:ordernumber/:zipcode" element={<HatchCollections/>} />
          <Route path="/my-orders/:orderid" element={<HatchCollections/>} />
          <Route path="/my-orders/return" element={<HatchCollections/>} />
          <Route path="/my-orders/return/:ordernumber/:zipcode" element={<HatchCollections/>} />
          <Route path="/my-orders/return/:orderid" element={<HatchCollections/>} />
          <Route path="/my-returns" element={<HatchCollections/>} />
          <Route path="/my-returns/:ordernumber/:zipcode" element={<HatchCollections/>} />
          <Route path="/my-returns/:orderid" element={<HatchCollections/>} /> */}

          {document.location.hostname === 'wearhumans.returns.fenixcommerce.com' && <Route exact path="/" element={<Navigate to="/login" replace/>} />}
          <Route path="" element = {<WearHumans/>}/>
          <Route path="/" element = {<WearHumans/>}/>
          <Route path="/login" element = {<WearHumans/>}/>
          <Route path="/my-orders" element={<WearHumans/>} />
          <Route path="/my-orders/:ordernumber/:zipcode" element={<WearHumans/>} />
          <Route path="/my-orders/:orderid" element={<WearHumans/>} />
          <Route path="/my-orders/return" element={<WearHumans/>} />
          <Route path="/my-orders/return/:ordernumber/:zipcode" element={<WearHumans/>} />
          <Route path="/my-orders/return/:orderid" element={<WearHumans/>} />
          <Route path="/my-returns" element={<WearHumans/>} />
          <Route path="/my-returns/:ordernumber/:zipcode" element={<WearHumans/>} />
          <Route path="/my-returns/:orderid" element={<WearHumans/>} />

          <Route path="/travelpro/login" element = {<TravelPro/>}/>
          <Route path="/travelpro/my-orders" element ={<TravelPro/>}/>

          <Route path="*" element ={<PageNotFound/>}/>
          </Routes>
          // <Outlet />
      // </Router>
    )
  
}

export default Routing;